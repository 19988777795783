import {createRouter, createWebHashHistory} from "vue-router"
import {post} from '../api/index'
import { ElMessage } from "element-plus"
import store from '../store';

const routes = [
    {
        path: '/404',
        name: '404',
        component: () => import('../views/404'),
        meta: {
            hideNavFoot: true
        }
    },
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home.vue'),
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('../views/About.vue')
    },
    {
        path: '/reward',
        name: 'Reward',
        component: () => import('../views/RewardSystem.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue'),
        meta: {
            hideNavFoot: true
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/Register.vue'),
        meta: {
            hideNavFoot: true
        }
    },
    {
        path: '/forum',
        name: 'Forum',
        component: () => import('../views/Forum.vue'),
    },
    {
        path: '/manageforum',
        name: 'ManageForum',
        component: () => import('../views/ManageForum.vue'),
    },
    {
        path: "/post",
        name: "PostDetail",
        component: () => import('../views/PostDetail.vue'),
      },
      {
        path: "/leaderboard",
        name: "LeaderBoard",
        component: () => import('../views/LeaderBoard.vue'),
      },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () => import('../views/ForgotPassword.vue')
    },
    {
        path: '/article',
        name: 'Article',
        component: () => import('../views/Article.vue'),
        // meta: {
        //     hideNavFoot: true
        // }
    },
    {
        path: '/management',
        name: 'Management',
        component: () => import('../views/Management.vue'),
        meta: { requiredAdminLevel: 1 }, // Moderator and above can access
        redirect: '/management/pending',
        children: [
            {
                path: 'approved',
                name: 'Approved',
                component: () => import('../views/ManagementSub/Approved.vue')
            },
            {
                path: 'disapproved',
                name: 'Disapproved',
                component: () => import('../views/ManagementSub/Disapproved.vue')
            },
            {
                path: 'pending',
                name: 'Pending',
                component: () => import('../views/ManagementSub/Pending.vue')
            },
            {
                path: 'moderatedComment',
                name: 'ModeratedComment',
                component: () => import('../views/ManagementSub/ModeratedComment.vue')
            },
            {
                path: 'pendingComment',
                name: 'PendingComment',
                component: () => import('../views/ManagementSub/PendingComment.vue')
            },
        ]
    },
    {
        path: '/account',
        name: 'Account',
        component: () => import('../views/Account.vue'),
        redirect: '/account/settings',
        children: [
            {
                path: 'settings',
                name: 'AccountSettings',
                component: () => import('../views/AccountSub/AccountSettings.vue')
            },
            {
                path: 'points',
                name: 'PointsHistory',
                component: () => import('../views/AccountSub/PointsHistory.vue')
            },
            {
                path: 'articles',
                name: 'ProposedArticles',
                component: () => import('../views/AccountSub/ProposedArticles.vue')
            },
            {
                path: 'reading',
                name: 'ReadingHistory',
                component: () => import('../views/AccountSub/ReadingHistory.vue')
            },
            {
                path: 'datamanagement',
                name: 'DataManagement',
                component: () => import('../views/AccountSub/DataManagement.vue')
            },
            {
                path: 'usermessage',
                name: 'UserMessage',
                component: () => import('../views/AccountSub/UserMessage.vue')
            },
            {
                path: 'moder',
                name: 'ModerationProgress',
                component: () => import('../views/AccountSub/ModerationProgress.vue')
            },
            {
                path: 'comments',
                name: 'ProposedComments',
                component: () => import('../views/AccountSub/ProposedComments.vue')
            },
        ]
    },
    {
        path: '/article-add',
        name: 'ArticleAdd',
        component: () => import('../views/ArticleAdd.vue'),
    },
    {
        path: '/questions-add',
        name: 'QuestionsAdd',
        component: () => import('../views/QuestionsAdd.vue'),
    }
    ,
    {
        path: '/preview-add',
        name: 'PreviewAdd',
        component: () => import('../views/PreviewAdd.vue'),
    },
    {
        path: '/user-manage',
        name: 'UserManage',
        component: () => import('../views/UserMange.vue'),
        meta: { requiredAdminLevel: 2 } // Only level 2 Admin can access
    },
    
    
    //此路由必须置于最下 This router has to be the last one
    {
        path: '/:pathMatch(.*)',
        redirect: '/404'
    },
]

const router =  createRouter({
    history:createWebHashHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    let token = store.state.Authorization;
    if (token) {
      post('/user/logged').then(res => {
        //if (to.name == 'Approved' || to.name == 'Disapproved' || to.name == 'Pending') {
        // CHANGED: Explicit check for Management access (Article Management)
        if (to.name == 'Management' || to.meta.requiredAdminLevel === 1) {  
          if (res.user.admin >= 1) {
            next();
          } else {
            ElMessage({
              type: 'error',
              message: 'You have no access to this page'
            });
            setTimeout(() => {
              next("/");
            }, 1500);
          }
        } else if (to.name == 'UserManage') {
          if (res.user.admin >= 2) {  // Only Level 2+ can access
            next();
          } else {
            ElMessage({
              type: 'error',
              message: 'You have no access to this page'
            });
            setTimeout(() => {
              next("/");
            }, 1500);
          }
        } // CHANGED: Dynamic enforcement of required admin levels
        else if (to.meta.requiredAdminLevel !== undefined) {  
            if (res.user.admin >= to.meta.requiredAdminLevel) {  
                next();
            } else {
                ElMessage({
                    type: 'error',
                    message: 'You have no access to this page'
                });
                setTimeout(() => {
                    next("/");
                }, 1500);
            }
        } else {
            next();
        }
      });
    } else {
      if (['/login', '/', '/about', '/reward', '/forgot-password', '/article', '/register', '/404'].includes(to.path)) {
        next();
      } else {
        next("/login");
      }
    }

    if ((from.path == '/article-add' || from.path == '/questions-add'
        || from.path == '/preview-add') && (to.path != '/questions-add' && to.path != '/preview-add')) {
        if(sessionStorage.getItem("newArticle")) {
            sessionStorage.removeItem("newArticle")
        }
        let i = 0
        while (sessionStorage.getItem("newQuestion"+i)) {
            sessionStorage.removeItem("newQuestion"+i)
            i+=1
        }
        if (sessionStorage.getItem("article")) {
            sessionStorage.removeItem("article")
        }
        if (sessionStorage.getItem("revise")) {
            sessionStorage.removeItem("revise")
        }
    }
})
export default router
