import axios from 'axios';
import store from '../store';
import router from '../router';

const axiosInstance = axios.create({
  //baseURL: process.env.VUE_APP_BASE_API_URL || 'http://localhost:8888',
  baseURL: 'https://mynewsscan.eu/api/',
  timeout: 10000,
  withCredentials: true
});

const errorHandle = (status, info) => {
  switch (status) {
    case 400:
      console.log("semantic failure");
      break;
    case 401:
      console.log("server verification failed");
      store.commit('logout');
      router.push('/login');
      break;
    case 403:
      console.log("server rejected");
      break;
    case 404:
      console.log("address error");
      break;
    case 500:
      console.log("server failure");
      break;
    case 502:
      console.log("server no answer");
      break;
    default:
      console.log(info);
      break;
  }
};

axiosInstance.interceptors.request.use(
  config => {
    const token = store.state.Authorization;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      errorHandle(error.response.status, error.response.data.message);
    } else {
      console.log('Network Error', error.message);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
